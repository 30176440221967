import React, { FC } from 'react';
import styled from 'styled-components';
import { Container } from '../../../components/Container';
import { Image } from '../../../components/Image';
import { COLORS, TYPOGRAPHY } from '../../../constants';

interface MonetizeProps {
  data: {
    title: string;
    subtitle: string;
    firstDescription: string;
    secondDescription: string;
    image: {
      localFile: {
        publicURL: string;
        extension: string;
        childImageSharp: any;
      };
    };
  };
}

const Monetize: FC<MonetizeProps> = ({ data }) => {
  return (
    <Root>
      <StyledContainer>
        <SectionLeft>
          <StyledImage localFile={data.image.localFile} alt={data.title} />
        </SectionLeft>
        <SectionRight>
          <Title dangerouslySetInnerHTML={{ __html: data.title }} />
          <Subtitle>{data.subtitle}</Subtitle>
          <Description>{data.firstDescription}</Description>
          <Description>{data.secondDescription}</Description>
        </SectionRight>
      </StyledContainer>
    </Root>
  );
};

const Root = styled.section`
  background-color: ${COLORS.white};
  @media (max-width: 767px) {
    overflow: hidden;
  }
`;

const StyledContainer = styled(Container)`
  padding-left: 0;
  display: flex;
  position: relative;
  @media (max-width: 1024px) {
    padding-left: 32px;
    padding-top: 60px;
    padding-bottom: 80px;
  }

  @media (max-width: 768px) {
    padding-left: 16px;
  }
  @media (max-width: 767px) {
    padding-bottom: 370px;
  }
`;

const SectionLeft = styled.div`
  display: flex;
  align-items: center;
  margin-right: 54px;
  margin-left: -36px;
  @media (max-width: 1200px) {
    margin-right: 40px;
  }
  @media (max-width: 1024px) {
    position: absolute;
    right: -100px;
    top: 60px;
    margin-left: 0;
  }
  @media (max-width: 767px) {
    top: auto;
    bottom: -20px;
    right: 50%;
    transform: translateX(50%);
  }
`;

const StyledImage = styled(Image)`
  max-width: 673px;
  margin-top: 20px;
  @media (max-width: 1024px) {
    width: 440px;
  }
  @media (max-width: 900px) {
    width: 340px;
  }
  @media (max-width: 767px) {
    width: 380px;
  }
`;

const SectionRight = styled.div`
  padding-top: 140px;
  padding-bottom: 235px;
  max-width: 510px;

  padding-left: 85px;
  box-sizing: content-box;
  @media (max-width: 1200px) {
    padding-left: 40px;
  }
  @media (max-width: 1024px) {
    border: 0;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
  @media (max-width: 767px) {
    max-width: none;
  }
`;

const Title = styled.div`
  h1,
  h2,
  p {
    ${TYPOGRAPHY.sectionTitle};
    strong {
      color: ${COLORS.red};
    }
  }
  margin-bottom: 30px;
  @media (max-width: 500px) {
    margin-bottom: 25px;
  }
`;

const Subtitle = styled.p`
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
  color: ${COLORS.black3};
  margin-bottom: 30px;
  @media (max-width: 1024px) {
    ${TYPOGRAPHY.sectionText};
    margin-bottom: 20px;
    max-width: 430px;
  }
  @media (max-width: 767px) {
    max-width: none;
  }
  @media (max-width: 500px) {
    margin-bottom: 10px;
  }
`;

const Description = styled.p`
  ${TYPOGRAPHY.sectionText};
  margin-bottom: 20px;
  max-width: 460px;
  &:last-child {
    margin-bottom: 0;
  }
  @media (max-width: 767px) {
    max-width: none;
  }
  @media (max-width: 500px) {
    margin-bottom: 10px;
  }
`;

export default Monetize;
