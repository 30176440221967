import React, { FC } from 'react';
import styled from 'styled-components';
import { Container } from '../../../components/Container';
import { Image } from '../../../components/Image';
import { COLORS, TYPOGRAPHY } from '../../../constants';

interface ControlInsightsProps {
  data: {
    controlTitle: string;
    subtitle: string;
    firstDescription: string;
    secondDescription: string;
    image: {
      localFile: {
        publicURL: string;
        extension: string;
        childImageSharp: any;
      };
    };
  };
}

const ControlInsights: FC<ControlInsightsProps> = ({ data }) => {
  return (
    <Root>
      <StyledContainer>
        <Title dangerouslySetInnerHTML={{ __html: data.controlTitle }} />
        <Subtitle>{data.subtitle}</Subtitle>
        <DescriptionWrap>
          <Description>{data.firstDescription}</Description>
          <Description>{data.secondDescription}</Description>
        </DescriptionWrap>
        <ImageWrap>
          <StyledImage
            localFile={data.image.localFile}
            alt={data.controlTitle}
          />
        </ImageWrap>
      </StyledContainer>
    </Root>
  );
};

const Root = styled.section`
  background-color: ${COLORS.white};
`;

const StyledContainer = styled(Container)`
  padding-top: 110px;
  padding-bottom: 36px;
  @media (max-width: 1024px) {
    padding-top: 60px;
  }
  @media (max-width: 768px) {
    padding-bottom: 40px;
  }
`;

const Title = styled.div`
  h1,
  h2,
  p {
    ${TYPOGRAPHY.sectionTitle};

    strong {
      color: ${COLORS.red};
    }
  }
  margin-bottom: 30px;
  /* @media (max-width: 650px) {
    max-width: 215px;
  } */
`;

const Subtitle = styled.p`
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
  color: ${COLORS.black3};
  margin-bottom: 30px;
  max-width: 680px;
  @media (max-width: 768px) {
    ${TYPOGRAPHY.sectionTextMobile};
    margin-bottom: 20px;
  }
`;

const DescriptionWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5%;
  margin-bottom: 135px;
  @media (max-width: 768px) {
    margin-bottom: 50px;
  }
  @media (max-width: 650px) {
    display: block;
  }
`;

const Description = styled.p`
  ${TYPOGRAPHY.sectionText};
  @media (max-width: 768px) {
    ${TYPOGRAPHY.sectionTextMobile};
  }
  @media (max-width: 650px) {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const ImageWrap = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledImage = styled(Image)`
  max-width: 667px;
  @media (max-width: 768px) {
    max-width: 483px;
  }
  @media (max-width: 500px) {
    max-width: 324px;
  }
`;

export default ControlInsights;
