import { graphql, useStaticQuery } from 'gatsby';

export interface TypeOfAudioValue {
  id: string;
  title: string;
  fields: {
    description: string;
    icon: {
      localFile: {
        publicURL: string;
        extension: string;
        childImageSharp: any;
      };
    };
  };
}

export function useTypesOfAudio(): TypeOfAudioValue[] {
  const { allWpTypeOfAudio } = useStaticQuery(graphql`
    query GET_TYPES_OF_AUDIO {
      allWpTypeOfAudio {
        nodes {
          id
          title
          fields: typesOfAudioField {
            description
            icon {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
                extension
                publicURL
              }
            }
          }
        }
      }
    }
  `);

  return allWpTypeOfAudio.nodes;
}
