import React, { FC } from 'react';
import styled from 'styled-components';
import { Container } from '../../../components/Container';
import { Image } from '../../../components/Image';
import { COLORS, TYPOGRAPHY } from '../../../constants';

interface BrandStationProps {
  data: {
    title: string;
    subtitle: string;
    firstDescription: string;
    secondDescription: string;
    image: {
      localFile: {
        publicURL: string;
        extension: string;
        childImageSharp: any;
      };
    };
  };
}

const BrandStation: FC<BrandStationProps> = ({ data }) => {
  return (
    <Root>
      <StyledContainer>
        <ImageWrap>
          <StyledImage localFile={data.image.localFile} alt={data.title} />
        </ImageWrap>
        <Content>
          <Title>{data.title}</Title>
          <Subtitle>{data.subtitle}</Subtitle>
          <Description>{data.firstDescription}</Description>
          <Description>{data.secondDescription}</Description>
        </Content>
      </StyledContainer>
    </Root>
  );
};

const Root = styled.section`
  background-color: ${COLORS.black2};
`;

const StyledContainer = styled(Container)`
  padding-left: 0;
  padding-top: 114px;
  padding-bottom: 87px;
  display: flex;
  align-items: center;
  @media (max-width: 1024px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  @media (max-width: 767px) {
    flex-direction: column-reverse;
    align-items: flex-start;
    padding-left: 16px;
    padding-bottom: 50px;
  }
`;

const ImageWrap = styled.div`
  position: relative;

  width: 100%;
  max-width: 684px;
  min-height: 426px;
  margin-right: 92px;
  overflow: hidden;
  @media (max-width: 1024px) {
    margin-right: 48px;
    min-height: 412px;
    min-width: 307px;
  }
  @media (max-width: 767px) {
    width: calc(100% + 32px);
    margin-left: -16px;
    max-width: none;
    min-height: auto;
  }
`;

const StyledImage = styled(Image)`
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  width: 760px;
  @media (max-width: 767px) {
    position: relative;
    left: 0;
    width: auto;
    transform: none;
  }
`;

const Content = styled.div`
  max-width: 480px;
  @media (max-width: 767px) {
    max-width: none;
    margin-bottom: 35px;
  }
`;

const Title = styled.h2`
  ${TYPOGRAPHY.sectionTitle};
  color: ${COLORS.white};
  margin-bottom: 30px;
  @media (max-width: 1024px) {
    font-size: 36px;
    line-height: 41px;
    margin-bottom: 35px;
  }
  @media (max-width: 767px) {
    margin-bottom: 30px;
  }
`;

const Subtitle = styled.p`
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
  color: ${COLORS.white};
  margin-bottom: 30px;
  @media (max-width: 1024px) {
    ${TYPOGRAPHY.sectionText};
    color: ${COLORS.white};
    margin-bottom: 20px;
  }
`;

const Description = styled.p`
  ${TYPOGRAPHY.sectionText};
  color: ${COLORS.white};
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
`;
export default BrandStation;
