import React, { FC } from 'react';
import styled from 'styled-components';
import { Container } from '../../../components/Container';
import { Image } from '../../../components/Image';
import { COLORS, TYPOGRAPHY } from '../../../constants';

interface SoundsLikeYouProps {
  data: {
    title: string;
    subtitle: string;
    firstDescription: string;
    secondDescription: string;
    image: {
      localFile: {
        publicURL: string;
        extension: string;
        childImageSharp: any;
      };
    };
  };
}

const SoundsLikeYou: FC<SoundsLikeYouProps> = ({ data }) => {
  return (
    <Root>
      <StyledContainer>
        <Content>
          <Title dangerouslySetInnerHTML={{ __html: data.title }} />
          <Subtitle>{data.subtitle}</Subtitle>
          <Description>{data.firstDescription}</Description>
          <Description>{data.secondDescription}</Description>
        </Content>
        <ImageWrap>
          <StyledImage localFile={data.image.localFile} alt="Apps" />
        </ImageWrap>
      </StyledContainer>
    </Root>
  );
};

const Root = styled.section`
  background-color: ${COLORS.white};
  border-top: 1px solid ${COLORS.lightGray};
  overflow: hidden;
`;

const StyledContainer = styled(Container)`
  position: relative;
  padding-right: 0;
  padding-top: 47px;

  padding-bottom: 117px;

  @media (max-width: 850px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  @media (max-width: 767px) {
    padding-bottom: 60px;
    padding-right: 16px;
  }
  @media (max-width: 500px) {
    padding-bottom: 60px;
  }
`;

const Content = styled.div`
  max-width: 530px;
  @media (max-width: 1200px) {
    max-width: 450px;
  }
  @media (max-width: 850px) {
    max-width: 360px;
  }
  @media (max-width: 767px) {
    max-width: none;
    margin-bottom: 35px;
  }
`;

const Title = styled.div`
  max-width: 450px;
  margin-bottom: 30px;
  h1,
  h2,
  p {
    ${TYPOGRAPHY.sectionTitle};

    strong {
      color: ${COLORS.red};
    }
  }
  @media (max-width: 850px) {
    h1,
    h2,
    p {
      font-size: 36px;
      line-height: 41px;
    }
    margin-bottom: 20px;
  }
  @media (max-width: 767px) {
    max-width: 300px;
  }
`;

const Subtitle = styled.p`
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
  color: ${COLORS.black3};

  margin-bottom: 30px;
  @media (max-width: 850px) {
    ${TYPOGRAPHY.sectionText}
    margin-bottom: 20px;
  }
`;

const Description = styled.p`
  ${TYPOGRAPHY.sectionText};
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const ImageWrap = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  object-fit: contain;
  /* @media (max-width: 1200px) {
    top: 50%;
    transform: translateY(-50%);
  }
  */
  @media (max-width: 767px) {
    position: relative;
    transform: unset;
    top: unset;
  }
  /* @media (max-width: 767px) {
    top: auto;
    transform: translateX(50%);
    right: 50%;
    bottom: -300px;
  }
  @media (max-width: 500px) {
    transform: none;
    right: -35px;
    bottom: -215px;
  }  */
`;

const StyledImage = styled(Image)`
  max-width: 631px;
  @media (max-width: 1200px) {
    width: 480px;
  }
  @media (max-width: 900px) {
    width: 400px;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
  /* @media (max-width: 500px) {
    width: 360px;
  }  */
`;

export default SoundsLikeYou;
