import { graphql, useStaticQuery } from 'gatsby';

export interface ServiceData {
  id: string;
  title: string;
  fields: {
    description: string;
    icon: {
      localFile: {
        publicURL: string;
        extension: string;
        childImageSharp: any;
      };
    };
  };
}

export function useFullServices(): ServiceData[] {
  const { allWpFullService } = useStaticQuery(graphql`
    query GET_FULL_SERVICES {
      allWpFullService {
        nodes {
          id
          title
          fields: fullServiceFields {
            description
            icon {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
                extension
                publicURL
              }
            }
          }
        }
      }
    }
  `);

  return allWpFullService.nodes;
}
