import React from 'react';
import MainLayout from '../layouts/MainLayout';
import { NewFeature } from '../components/NewFeature';
import { ReleaseNow } from '../components/ReleaseNow';
import { Hero } from '../pageComponents/MainPage/Hero';
import { BrandStation } from '../pageComponents/BrandsPage/BrandStation';
import { SoundsLikeYou } from '../pageComponents/BrandsPage/SoundsLikeYou';
import { TypesOfAudio } from '../pageComponents/BrandsPage/TypesOfAudio';
import { Monetize } from '../pageComponents/BrandsPage/Monetize';
import { MusicBranding } from '../pageComponents/BrandsPage/MusicBranding';
import { ControlInsights } from '../pageComponents/BrandsPage/ControlInsights';
import { Cloud360 } from '../pageComponents/BrandsPage/Cloud360';
import { Curators } from '../pageComponents/BrandsPage/Curators';
import { Seo } from '../components/Seo';

const BrandsPageTemplate = ({ pageContext }: any) => {
  const {
    brandsHeroSection,
    brandStation,
    soundsLikeYou,
    monetizeSection,
    musicBrandingSection,
    controlInsights,
    fullService,
    curatedSection,
  } = pageContext.brandsFields;
  const newFeature = pageContext.newFeature;
  const releaseNow = pageContext.releaseNow;
  const seo = pageContext.seo;
  const title = pageContext.title;

  return (
    <MainLayout>
      <Seo
        title={seo.seoTitle ? seo.seoTitle : title}
        description={seo.seoDescription ? seo.seoDescription : ''}
      />
      <Hero data={brandsHeroSection} />
      <BrandStation data={brandStation} />
      <SoundsLikeYou data={soundsLikeYou} />
      <TypesOfAudio />
      <Monetize data={monetizeSection} />
      <NewFeature data={newFeature} />
      <MusicBranding data={musicBrandingSection} />
      <ControlInsights data={controlInsights} />
      <Cloud360 data={fullService} />
      {/* <Curators data={curatedSection} /> */}
      <ReleaseNow data={releaseNow} />
    </MainLayout>
  );
};
export default BrandsPageTemplate;
