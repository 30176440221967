import React, { FC } from 'react';
import styled from 'styled-components';
import { Container } from '../../../components/Container';
import { COLORS, TYPOGRAPHY } from '../../../constants';

interface Card {
  title: string;
  description: string;
}

interface MusicBrandingProps {
  data: {
    musicBrandingTitle: string;
    subtitle: string;
    cards: { [key: string]: Card };
  };
}

const MusicBranding: FC<MusicBrandingProps> = ({ data }) => {
  const cards = Object.keys(data.cards).map(keyName => data.cards[keyName]);

  return (
    <Root>
      <StyledContainer>
        <Title dangerouslySetInnerHTML={{ __html: data.musicBrandingTitle }} />
        <Subtitle>{data.subtitle}</Subtitle>
        <CardsList>
          {cards.map((item, index) => {
            return (
              <CardItem key={index}>
                <CardTitle>{item.title}</CardTitle>
                <CardDescription>{item.description}</CardDescription>
              </CardItem>
            );
          })}
        </CardsList>
      </StyledContainer>
    </Root>
  );
};

const Root = styled.section`
  background-color: ${COLORS.lightGray};
`;

const StyledContainer = styled(Container)`
  padding-top: 110px;
  padding-bottom: 120px;
  @media (max-width: 1024px) {
    padding-top: 60px;
    padding-bottom: 85px;
  }
  @media (max-width: 650px) {
    padding-bottom: 54px;
  }
`;

const Title = styled.div`
  h1,
  h2,
  p {
    ${TYPOGRAPHY.sectionTitle};
    text-align: center;
    strong {
      color: ${COLORS.red};
    }
  }
  margin-bottom: 30px;

  @media (max-width: 1024px) {
    h1,
    h2,
    p {
      font-size: 36px;
      line-height: 41px;
    }
  }
  @media (max-width: 650px) {
    h1,
    h2,
    p {
      text-align: left;
    }
  }
`;

const Subtitle = styled.p`
  max-width: 750px;
  font-size: 36px;
  line-height: 54px;
  text-align: center;
  font-weight: 600;
  color: ${COLORS.black2};
  margin: 0 auto;
  margin-bottom: 60px;
  @media (max-width: 1024px) {
    font-size: 24px;
    line-height: 36px;
    max-width: 500px;
    margin-bottom: 40px;
  }
  @media (max-width: 650px) {
    text-align: left;
    margin: 0;
    margin-bottom: 24px;
  }
  @media (max-width: 500px) {
    ${TYPOGRAPHY.sectionTextMobile};
  }
`;

const CardsList = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  @media (max-width: 1024px) {
    gap: 16px;
  }
  @media (max-width: 650px) {
    display: block;
  }
`;

const CardItem = styled.li`
  background-color: ${COLORS.white};
  border-radius: 15px;
  padding: 50px 40px;
  @media (max-width: 1024px) {
    padding: 30px 24px;
  }
  @media (max-width: 650px) {
    padding: 30px 20px;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const CardTitle = styled.h3`
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: ${COLORS.black2};
  margin-bottom: 20px;
  @media (max-width: 1024px) {
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    margin-bottom: 10px;
  }
  @media (max-width: 950px) {
    font-size: 14px;
    line-height: 21px;
  }
  @media (max-width: 650px) {
    font-size: 22px;
    line-height: 32px;
    text-align: left;
  }
`;

const CardDescription = styled.p`
  font-size: 18px;
  line-height: 31px;
  color: ${COLORS.black2};
  @media (max-width: 1024px) {
    ${TYPOGRAPHY.sectionTextMobile};
  }
`;

export default MusicBranding;
