import React, { FC, useRef, useState } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Virtual } from 'swiper';
import { Container } from '../../../components/Container';
import { COLORS, TYPOGRAPHY } from '../../../constants';
import { useTypesOfAudio } from '../../../hooks/useTypesOfAudio';
import { Image } from '../../../components/Image';

const TypesOfAudio: FC = () => {
  const types = useTypesOfAudio();
  const prevBtnRef = useRef<HTMLButtonElement>(null);
  const nextBtnRef = useRef<HTMLButtonElement>(null);
  const paginationRef = useRef<HTMLParagraphElement>(null);
  const [swiperRef, setSwiperRef] = useState<any>(null);

  return (
    <Root>
      <StyledContainer>
        <Title>Offer all types of audio</Title>
        <SliderWrapper>
          <StyledSwiper
            modules={[Virtual, Navigation, Pagination]}
            breakpoints={{
              650: {
                slidesPerView: 4,
                spaceBetween: 24,
              },
              500: {
                slidesPerView: 2,
                spaceBetween: 20,
                slidesOffsetBefore: 16,
                slidesOffsetAfter: 16,
              },
              320: {
                slidesPerView: 1.4,
                spaceBetween: 20,
                slidesOffsetBefore: 16,
                slidesOffsetAfter: 16,
              },
            }}
            onInit={swiper => {
              if (innerWidth < 650) {
                swiper.init();
              } else {
                swiper.disable();
              }
            }}
            onSwiper={swiper => setSwiperRef(swiper)}
            init={false}
            pagination={{
              type: 'custom',
              el: paginationRef.current,
              renderCustom: (swiper, current, total) => `${current}/${total}`,
            }}
            navigation={{
              prevEl: prevBtnRef.current!,
              nextEl: nextBtnRef.current!,
            }}
          >
            {types.map((item, index) => {
              return (
                <SwiperSlide key={item.id} virtualIndex={index}>
                  <IconWrap>
                    <Icon
                      localFile={item.fields.icon.localFile}
                      alt={item.title}
                    />
                  </IconWrap>
                  <ItemTitle>{item.title}</ItemTitle>
                  <Description>{item.fields.description}</Description>
                </SwiperSlide>
              );
            })}
          </StyledSwiper>
          <NavigationWrap>
            <PrevNavigationBtn ref={prevBtnRef} />
            <PaginationCounter ref={paginationRef} />
            <NavigationBtn ref={nextBtnRef} />
          </NavigationWrap>
        </SliderWrapper>
      </StyledContainer>
    </Root>
  );
};

const Root = styled.section`
  background-color: ${COLORS.lightGray};
`;

const StyledContainer = styled(Container)`
  padding-top: 110px;
  padding-bottom: 128px;
  @media (max-width: 768px) {
    padding: 60px 0 76px 0;
  }
  @media (max-width: 650px) {
    padding-top: 50px;
    padding-bottom: 100px;
  }
`;

const Title = styled.h2`
  ${TYPOGRAPHY.sectionTitle};
  margin-bottom: 74px;
  text-align: center;
  @media (max-width: 768px) {
    margin-bottom: 51px;
  }
  @media (max-width: 650px) {
    text-align: left;
    margin-bottom: 25px;
    padding-left: 16px;
    padding-right: 16px;
  }
`;
const SliderWrapper = styled.div`
  position: relative;
`;

const StyledSwiper = styled(Swiper)`
  .swiper-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
  .swiper-slide {
    padding: 53px 20px 80px;

    background-color: ${COLORS.white};
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  @media (max-width: 1024px) {
    .swiper-slide {
      padding: 30px 14px 25px;
      height: auto;
    }
  }
  @media (max-width: 650px) {
    .swiper-wrapper {
      display: flex;
    }
  }
  @media (max-width: 500px) {
    .swiper-slide {
      padding: 50px 35px 80px;
    }
  }
`;

const IconWrap = styled.div`
  max-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  @media (max-width: 1024px) {
    margin-bottom: 14px;
  }
  @media (max-width: 500px) {
    margin-bottom: 35px;
  }
`;

const Icon = styled(Image)`
  width: 80px;
  @media (max-width: 1024px) {
    width: 50px;
  }
  @media (max-width: 500px) {
    width: 73px;
  }
`;

const ItemTitle = styled.p`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: ${COLORS.black3};
  margin-bottom: 23px;
  @media (max-width: 1024px) {
    font-size: 18px;
    line-height: 22px;
  }
  @media (max-width: 500px) {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 18px;
  }
`;

const Description = styled.p`
  ${TYPOGRAPHY.sectionText};

  @media (max-width: 1024px) {
    font-size: 14px;
    line-height: 21px;
  }
  @media (max-width: 650px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const NavigationWrap = styled.div`
  display: none;
  position: absolute;
  background-color: ${COLORS.red};
  left: 16px;
  bottom: -38px;
  z-index: 2;
  @media (max-width: 650px) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 19px 11px;
  }
`;

const NavigationBtn = styled.button`
  cursor: pointer;
  width: 28px;
  height: 11px;
  background: url('/images/arrow-white.svg') no-repeat center;
  background-size: cover;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.5;
  }
  &:disabled {
    opacity: 0.5;
  }
`;

const PrevNavigationBtn = styled(NavigationBtn)`
  transform: rotate(180deg);
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.5;
  }
`;

const PaginationCounter = styled.p`
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: ${COLORS.white};
  margin-left: 10px;
  margin-right: 10px;
`;

export default TypesOfAudio;
